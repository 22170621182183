import TgIco from "../../../../assets/tg-ico.svg";
import VkIco from "../../../../assets/vk-ico.svg";
import TenChatIco from "../../../../assets/ten-ico.png";
import './index.scss';

const Footer = () => {
  return (
    <footer className="footer">
    <div className="footer__info">
      <a className="footer__info__link" href="tel:+79272774195">+7 927 277 41 95</a>
      <a className="footer__info__link" href="mailto:orson@inbox.ru">orson@inbox.ru</a>
      <p className="footer__info__link">2025 г. Рыжова Наталья Викторовна</p>
    </div>
    <nav className="footer__nav">
      <a className="footer__nav__link" href="#services">Услуги</a>
      <a className="footer__nav__link" href="#testimonails">Отзывы</a>
      <a className="footer__nav__link" href="https://t.me/rnv_adv" target="_blanc"><img className="footer__nav__link__socioIco" src={TgIco} alt="vk"/></a>
      <a className="footer__nav__link" href="https://vk.com/ryzhovnv" target="_blanc"><img className="footer__nav__link__socioIco" src={VkIco} alt="vk"/></a>
      <a className="footer__nav__link" href="https://tenchat.ru/2570094" target="_blanc"><img className="footer__nav__link__socioIco" src={TenChatIco} alt="tenChat"/></a>
    </nav>
  </footer>
  )
}

export default Footer;
